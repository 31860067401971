import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Print } from '@mui/icons-material/';
import { LoadingButton } from "@mui/lab";
import ReusableDialog from "../../components/Dialog";
import { useSearchParams } from "react-router-dom";
import { downloadChangeOrderPDF } from "../../apiCalls";
import { UIContext, UIState } from "../../providers/UIProvider";
import Loading from "../../components/Loading";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {
  disabled: boolean;
  mode: "submit" | "printPreview";
  printFlow: (buyerCount: number) => void;
  title: string;
};

const COPDFPreview = ({ disabled, mode, printFlow, title }: Props) => {
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");
  const [PDFUrl, setPDFUrl] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [state] = useContext<UIState | any>(UIContext);
  const [buyerCount, setBuyerCount] = useState<number>(2);
  const [oldBuyerCount, setOldBuyerCount] = useState<number>(2);
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );

  useEffect(() => {
    if (
      intentionId &&
      state.pendingItems.length > 0 &&
      ((state.changeOrder.lastSaved &&
        state.changeOrder.lastSaved !== lastSaved) ||
        oldBuyerCount !== buyerCount)
    ) {
      setIsDownloading(true);
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          {
            changeOrderId: intentionId,
            isSubmitPreview: mode === "submit",
            buyerCount: mode === "printPreview" ? 0 : buyerCount
          },
          async (res: {
            fileData: "string";
            contentType: "string";
            blobName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              blobName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);
              setPDFUrl(blobUrl);
            }

            downloadPDF(res.blobName, res.contentType, res.fileData);
            setIsDownloading(false);
            setIsSaving(false);
          },
          (err: any) => {
            setIsDownloading(false);
            setIsSaving(false);
          }
        );
      setLastSaved(state.changeOrder.lastSaved);
      setOldBuyerCount(buyerCount);
    }

    return () => {
      setPDFUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.lastSaved, buyerCount]);

  const submitToolTip = () => {
    if(!disabled) return undefined;
    if(disabled) {
      if((!state.changeOrder.customerBuyer || !state.changeOrder.customerBuyer.salesforceAccountId) 
        && (!state.changeOrder.address?.jobID)) {
          return "Verify Buyer and Address";
      }
      if(!state.changeOrder.customerBuyer || !state.changeOrder.customerBuyer.salesforceAccountId) 
        return "Verify Buyer";
      if(!state.changeOrder.address?.jobID)
        return "Verify Address";
    }
    return undefined;
  }

  const submitFooter = () => {
    return (
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "10px 24px",
        }}
      >
        <FormControl>
          <InputLabel id="buyer-signatures-label">
            Number of Buyer signatures
          </InputLabel>
          <Select
            label="Number of Buyer signatures"
            labelId="subyer-signatures-label"
            id="buyer-signatures"
            value={buyerCount}
            onChange={(event: any) =>
              setBuyerCount(parseInt(event.target.value))
            }
            sx={{
              textAlign: "right",
              width: "185px",
            }}
          >
            {[1, 2, 3, 4].map((bc: number) => (
              <MenuItem key={`mi-${bc}`} value={bc}>
                {bc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={isSaving}
            loading={isSaving}
            onClick={() => {
              setOpen(false);
              setIsSaving(true);
              printFlow(buyerCount);
            }}
            variant="contained"
            color="primary"
          >
            Confirm
          </LoadingButton>
        </Stack>
      </DialogActions>
    );
  };

  const printPreviewFooter = () => {
    return (
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "10px 24px",
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              setOpen(false);
              printFlow(0);
            }}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => {
              const previewIframe = document.getElementById("pdfPreview") as HTMLIFrameElement;
              if(previewIframe) previewIframe.contentWindow?.print();
            }}
            variant="contained"
            color="primary"
          >Print</Button>
        </Stack>
      </DialogActions>
    );
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        content={
          isDownloading ? (
            <Loading />
          ) : (
            <>
              <DialogContent sx={{ pb: 1 }}>
                <Box
                  component="iframe"
                  id="pdfPreview"
                  sx={{
                    height: 600,
                    width: "100%",
                    maxHeight: { xs: 400, md: 700 },
                    maxWidth: { xs: 500, md: 852 },
                  }}
                  src={PDFUrl}
                />
              </DialogContent>
              { mode === "submit" && submitFooter() }
              { mode === "printPreview" && printPreviewFooter() }
            </>
          )
        }
        buttonVariant="contained"
        buttonText={ mode === "submit" ? "Submit" : ""}
        disabled={disabled}
        icon={ mode === "printPreview" ? <Print /> : null}
        title={title}
        toolTipTitle={submitToolTip()}
        buttonColor="primary"
      />
    </>
  );
};

export default COPDFPreview;
