import { useState, useContext } from "react";
import { UIState, UIContext, HighlandCategoryOption, OptionTypes } from "../../providers/UIProvider";
import { Box, Button, DialogContent, DialogActions } from "@mui/material";
import { PlaylistRemove } from '@mui/icons-material/';
import ReusableDialog from "../../components/Dialog";

interface ConfirmDeleteAllProps {
  highlandCategory: HighlandCategoryOption;
  mode: "quote" | "edit" | "view";
  onClose?: any;
};

const ConfirmDeleteAll = (props:ConfirmDeleteAllProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const handleClose = () => {
    setIsOpen(false);
    if(props.onClose) props.onClose();
  }

  const execDeleteAll = () => {
    const newPendingItems = state.pendingItems
      .filter((item: OptionTypes) => item.highlandCategory.key !== props.highlandCategory.key);
    dispatch({
      type: props.mode === "edit" ? "ChangeOrderPending" : "QuotePending",
      payload: newPendingItems,
      source: "DeleteAll",
    });
    setIsOpen(false);
  }
  
  return (
    <ReusableDialog
      buttonVariant="outlined"
      buttonFullWidth={false}
      disabled={false}
      setIsOpen={(value) => setIsOpen(value)}
      icon={<PlaylistRemove />}
      isOpen={isOpen}
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Box marginY={(theme) => theme.spacing(2)}>
                <p>Delete all options in <strong>{props.highlandCategory.label}</strong> Category?</p>
              </Box>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                execDeleteAll();
                handleClose();
              }}
              variant="contained"
              color="primary"
            >
              Delete
            </Button>
          </DialogActions>
        </>
      }
      title={`Delete All ${props.highlandCategory.label} Options`}
      toolTipTitle="Delete All"
    />
  );
}

export default ConfirmDeleteAll;