import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { Print } from '@mui/icons-material/';
import ReusableDialog from "../../components/Dialog";
import { downloadQuotePDF } from "../../apiCalls";
import { UIContext, UIState } from "../../providers/UIProvider";
import Loading from "../../components/Loading";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {
  disabled: boolean;
  printFlow: (buyerCount: number) => void;
  quoteId: string;
  title: string;
};

const QuotePDFPreview = ({ disabled, printFlow, quoteId, title }: Props) => {
  const [PDFUrl, setPDFUrl] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [state] = useContext<UIState | any>(UIContext);
  const DownloadQuoteAccess = useAccessControl(
    "Quote",
    "QuoteSummary"
  );

  useEffect(() => {
    if (
      quoteId &&
      state.pendingItems.length > 0 &&
      ((state.quote.lastSaved &&
        state.quote.lastSaved !== lastSaved))
    ) {
      setIsDownloading(true);
      DownloadQuoteAccess &&
        downloadQuotePDF(
          { quoteId: quoteId },
          async (res: {
            fileData: "string";
            contentType: "string";
            blobName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              blobName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);
              setPDFUrl(blobUrl);
            }

            downloadPDF(res.blobName, res.contentType, res.fileData);
            setIsDownloading(false);
            //setIsSaving(false);
          },
          (err: any) => {
            setIsDownloading(false);
            //setIsSaving(false);
          }
        );
      setLastSaved(state.quote.lastSaved);
      //setOldBuyerCount(buyerCount);
    }

    return () => {
      setPDFUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.lastSaved]);

  const printPreviewFooter = () => {
    return (
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "10px 24px",
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              setOpen(false);
              printFlow(0);
            }}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => {
              const previewIframe = document.getElementById("pdfPreview") as HTMLIFrameElement;
              if(previewIframe) previewIframe.contentWindow?.print();
            }}
            variant="contained"
            color="primary"
          >Print</Button>
        </Stack>
      </DialogActions>
    );
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        content={
          isDownloading ? (
            <Loading />
          ) : (
            <>
              <DialogContent sx={{ pb: 1 }}>
                <Box
                  component="iframe"
                  id="pdfPreview"
                  sx={{
                    height: 600,
                    width: "100%",
                    maxHeight: { xs: 400, md: 700 },
                    maxWidth: { xs: 500, md: 852 },
                  }}
                  src={PDFUrl}
                />
              </DialogContent>
              { printPreviewFooter() }
            </>
          )
        }
        buttonVariant="contained"
        disabled={disabled}
        icon={ <Print /> }
        title={title}
        toolTipTitle=""
        buttonColor="primary"
      />
    </>
  );
};

export default QuotePDFPreview;
