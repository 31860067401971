import { useContext, useEffect, useState } from "react";
import { isElementInViewPort } from "../../common/fx";
import { 
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider"
import { Delete } from "@mui/icons-material";

type Props = {
  category: HighlandCategoryOption
}

export default function COCategoryButton(props:Props) {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isWaitingForNew, setIsWaitingForNew] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>();
  const [isDeletable, setIsDeletable] = useState<boolean>();

  /*useEffect(() => {
    reCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

  useEffect(() => {
    if(state.pendingItems && state.pendingCategories) {
      reCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pendingItems, state.pendingCategories]);

  useEffect(() => {
    if (state.changeOrder.status === "blocked") {
      setIsDisabled(true);
    }
  }, [state.changeOrder.status]);

  useEffect(() => {
    if(isWaitingForNew) {
      setIsWaitingForNew(false);
      setTimeout(() => {
        const newItem = state.pendingItems.find((item:OptionTypes) =>
          item.highlandCategory.key === props.category.key);
        if(newItem) {
          const nextElementType = props.category.key === "custom" ? 'description' : 'option';
          const nextElement =  document.getElementById(`${newItem.id}_${nextElementType}`);
          nextElement?.focus();
          if(!isElementInViewPort(nextElement)) {
            nextElement?.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pendingItems]);

  const reCount = () => {
    const categoryItems:OptionTypes[] = state.pendingItems
      .filter((item:OptionTypes) =>
        item.highlandCategory.key === props.category.key && item.description && item.option
      );
    setIsDisabled(categoryItems.length > 0 || false);
    const isDeletable = state.pendingCategories
      .some((pendingCategory:HighlandCategoryOption) =>
        props.category.key === pendingCategory.key && categoryItems.length === 0
      );
    setIsDeletable(isDeletable);
  }

  const handleAction = () => {
    if (isDeletable) {
      dispatch({ type: "RemovePendingCategory", payload: props.category });
    } else if ( !isDisabled) {
      setIsWaitingForNew(true);
      dispatch({ type: "AddPendingCategory", payload: props.category });
    }
  }

  return (
    <div
      className={`highland-category--button${isDisabled ? ' disabled' : ''}${isDeletable ? ' deletable' : ''}`}
      onBlur={() => setIsFocused(false)}
      onClick={handleAction}
      onFocus={() => setIsFocused(true)}
      onKeyDown={(event) => {
        if (event.code === "Space" || event.code === "Enter") {
          event.preventDefault();
          handleAction();
        }
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      tabIndex={0}
    >
      {isDeletable && (isFocused || isHover)? <Delete /> : props.category.icon}
      &nbsp;{props.category.label}
    </div>
  );
}
