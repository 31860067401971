import { useState, useEffect } from "react";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { CheckCircle, Info, Edit, Done } from "@mui/icons-material";
import { isNil } from "lodash";
import "./ValidatedInput.scss";

export interface ValidatedInputProps {
  className: string;
  dropdownData?: any[];
  editTitle?: string;
  styleOverride?: any;
  finalLabel?: (params: any) => string;
  inputVariant: "outlined" | "filled" | "standard";
  isVerified?: boolean | null;
  mode: "editor" | "readonly";
  nonVerifiedTitle?: string | null;
  onChange?: (params: any) => any;
  disabled?: boolean;
  onDropdownType?: (params: any) => any;
  optionLabel?: (params: any) => string;
  optionDisabled?: (params: any) => boolean;
  type: "text" | "autocomplete";
  title?: string;
  value: any;
  verify: boolean;
  verifiedTitle?: string | null;
  saveTitle?: string;
  width?: number | null;
}

export default function ValidatedInput(props: ValidatedInputProps) {
  const [isVerified, setIsVerified] = useState<boolean | null>(
    props.isVerified || null
  );
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(props.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsVerified(props.isVerified || null);
  }, [props.isVerified]);

  useEffect(() => {
    if (props.value !== inputValue) setInputValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    setIsLoading(false);
  }, [props.dropdownData]);

  const handleChange = () => {
    props.onChange && props.onChange(inputValue);
    setIsBeingEdited(false);
  };

  return (
    <div
      style={props?.styleOverride}
      className={`validated-input ${props.className}`}
    >
      {props.verify && isVerified && !isBeingEdited && (
        <>
          {props.verifiedTitle ? (
            <span title={props.verifiedTitle}>
              <CheckCircle
                data-testid="success-icon"
                className="validated-input--icon validated-input--icon--success"
              />
            </span>
          ) : (
            <CheckCircle
              data-testid="success-icon"
              className="validated-input--icon validated-input--icon--success"
            />
          )}
        </>
      )}
      {props.verify && !isVerified && !isBeingEdited && (
        <>
          {props.nonVerifiedTitle ? (
            <span title={props.nonVerifiedTitle}>
              <Info
                data-testid="info-icon"
                className="validated-input--icon validated-input--icon--info"
              />
            </span>
          ) : (
            <Info
              data-testid="info-icon"
              className="validated-input--icon validated-input--icon--info"
            />
          )}
        </>
      )}

      {isBeingEdited ? (
        <>
          {props.type === "text" && (
            <TextField
              sx={props?.styleOverride}
              inputProps={{
                maxLength: 25,
                "data-testid": "input-box",
              }}
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              onKeyDown={(event) => {
                switch (event.key) {
                  case "ArrowUp":
                  case "ArrowDown":
                  case "ArrowLeft":
                  case "ArrowRight":
                  case " ":
                    event.stopPropagation();
                    break;
                  case "Enter":
                    handleChange();
                    break;
                }
              }}
              variant={props.inputVariant}
            />
          )}
          {props.type === "autocomplete" && (
            <Autocomplete
              getOptionDisabled={
                props.optionDisabled ? props.optionDisabled : undefined
              }
              getOptionLabel={props.optionLabel}
              loading={isLoading}
              multiple={false}
              onChange={(e, value: any) => {
                if (!isNil(value)) {
                  props.onChange && props.onChange(value);
                  setIsBeingEdited(false);
                }
              }}
              onInputChange={
                props.onDropdownType
                  ? (e: any, value: any) => {
                      if (!isNil(value) && value.length >= 3) {
                        if (e && e?.type === "click") {
                          props.onChange && props.onChange(value);
                          setIsBeingEdited(false);
                        }
                        if (e && e?.type !== "click") {
                          setInputValue(value);
                          if (props.onDropdownType) {
                            props.onDropdownType(value);
                            setIsLoading(true);
                          }
                        }
                      }
                    }
                  : undefined
              }
              options={props.dropdownData || []}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  variant={props.inputVariant}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "input-box",
                  }}
                />
              )}
              size="medium"
              sx={{ width: props.width ? `${props.width}px` : "180px" }}
              value={props.value || null}
            />
          )}
          <span
            title={props.saveTitle}
            style={{ fontSize: "1.25rem", lineHeight: "1" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleChange()}
            >
              <Done
                className="validated-input--cta validated-input--cta--save"
                sx={{ fontSize: "1.25rem", ml: 0.5 }}
              />
            </IconButton>
          </span>
        </>
      ) : (
        <>
          {props.mode === "editor" && !props.disabled && (
            <span title={props.editTitle}>
              <IconButton
                color="inherit"
                disabled={props.disabled ?? false}
                aria-label="open drawer"
                onClick={() => setIsBeingEdited(true)}
              >
                <Edit
                  data-testid="edit-icon"
                  className="validated-input--cta validated-input--cta--edit"
                  sx={{ fontSize: "1.25rem", ml: 0.5 }}
                />
              </IconButton>
            </span>
          )}
          <span
            className={`${props.title ? "validated-input--more-info" : ""}`}
            title={props.title}
          >
            {props.type === "text" && props.value}
            {props.type === "autocomplete" &&
              (props.finalLabel
                ? props.finalLabel(props.value)
                : props.optionLabel && props.optionLabel(props.value))}
          </span>
        </>
      )}
    </div>
  );
}
