import React, { useContext, useState, useEffect } from "react";
import { UIContext } from "../../providers/UIProvider";
import { updateKeyword, updateQuote } from "../../apiCalls";
import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ValidatedInput from "../ValidatedInput";
import { handleUnchangedNavigation, dispatchError } from "../../common/fx";
import logo from "../../assets/images/logo-white.png";
import "./Header.scss";

type Props = {
  headerRight: React.ReactNode;
  headerLeft: React.ReactNode;
};

const Header = (props: Props) => {
  const [state, dispatch]: any = useContext(UIContext);
  const [coKeyword, setCOKeyword] = useState<string>("");
  const [quoteKeyword, setQuoteKeyword] = useState<string>("");
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.changeOrder.currentIntention) {
      setCOKeyword(
        state.changeOrder.keyword
          ? state.changeOrder.keyword
          : state.changeOrder.currentIntention.number
      );
    }
  }, [state.changeOrder?.keyword, state.changeOrder?.currentIntention]);

  useEffect(() => {
    
    if(state.quote.id)
      setQuoteKeyword(state.quote.keyword);
  }, [state.quote?.keyword, state.quote.id]);

  const handleUpdateCOKeyword = (newKeyword: string) => {
    let intentionId = searchParams.get("intentionId");
    updateKeyword(
      {
        intentionId: (intentionId as string) || "",
        keyword: newKeyword,
      },
      (res: any) => {
        dispatch({
          type: "ChangeOrder",
          payload: {
            keyword: newKeyword,
            lastSaved: new Date(),
          },
          source: "updateCOKeyword",
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const handleUpdateQuoteKeyword = (newKeyword: string) => {
    updateQuote(
      state.quote.id,
      {
        quoteKeyword: newKeyword
      },
      (res:any) => {
        dispatch({
          type: "Quote",
          payload: {
            keyword: newKeyword,
            lastSaved: new Date(),
          },
          source: "updateQuoteKeyword"
        });
      },
      (err:any) => dispatch(dispatchError(err.response.data))
    )
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
    >
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            {props.headerLeft}

            {
              <img
                className="logo"
                onClick={() =>
                  handleUnchangedNavigation(
                    "/",
                    state.changeOrder.status,
                    navigate,
                    dispatch
                  )
                }
                src={logo}
                height={60}
                alt="logo"
              />
            }

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                mx: 2,
              }}
            >
              {state.navbar.title ? (
                <Typography variant="h6">{state.navbar.title}</Typography>
              ) : (
                state.navbar.reactElement
              )}

              {location.pathname === "/edit" &&
                state.changeOrder.currentIntention !== null && (
                  <>
                    &nbsp;
                    <ValidatedInput
                      className="header-keyword"
                      editTitle="Edit C.O. keyword"
                      inputVariant="standard"
                      mode="editor"
                      onChange={handleUpdateCOKeyword}
                      type="text"
                      value={coKeyword}
                      verify={false}
                      saveTitle="Save this keyword"
                    />
                  </>
                )
              }

              {location.pathname === "/quote" &&
                state.quote.id !== "" && (
                  <>
                    &nbsp;
                    <ValidatedInput
                      className="header-keyword"
                      editTitle="Edit Quote keyword"
                      inputVariant="standard"
                      mode="editor"
                      onChange={handleUpdateQuoteKeyword}
                      type="text"
                      value={quoteKeyword}
                      verify={false}
                      saveTitle="Save this keyword"
                    />
                  </>
                )
              }
            </Box>
          </Stack>

          {props.headerRight}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
