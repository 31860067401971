import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import JobSummary from "./pages/JobSummary";
import ChangeOrders from "./pages/ChangeOrders";
import Edit from "./pages/Edit";
import Quote from "./pages/Quote";
import Admin from "./pages/Admin";
import TemplateManagement from "./pages/TemplateManagement";
import SystemNotifications from "./pages/SystemNotifications";
import Catalog from "./pages/Catalog";
import Cancel from "./pages/Cancel";
import { Container, Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignInButton } from "./components/Buttons/SignInButton";
import { Route, Routes } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import Confirm from "./pages/CreateConfirm";
import SnackbarAlert from "./components/snackbarAlert";
import AdminRoute from "./components/AdminWrapper";
import "./styles/App.scss";
import Summary from "./pages/Summary/index";
import View from "./pages/View";
import AdminRoles from "./pages/AdminRoles";
import EditRole from "./pages/AdminRoles/role";
import TemplateSummary from "./pages/TemplateManagement/templateSummary";
import NotificationDialog from "./pages/SystemNotifications/notificationDialog";
import DynamicReports from "./pages/DynamicReports";
import DynamicReportDetail from "./pages/DynamicReportDetail";
import BuyerQuoteSummary from "./pages/BuyerQuoteSummary";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const ProfileContent = () => {
  return <Home />;
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const AuthHome = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please Sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <>
      <Layout>
        <SnackbarAlert />
        <NotificationDialog />
        <Routes>
          <Route path="/" element={<AuthHome />} />
          <Route
            path="/buyer-quote-summary/*"
            element={<BuyerQuoteSummary />}
          />
          <Route path="/job-summary/*" element={<JobSummary />} />
          <Route path="/joboptions-summary/*" element={<Summary />} />

          <Route path="/cancel" element={<Cancel />} />
          <Route
            path="/admin/usermanagement"
            element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            }
          />
          <Route
            path="/template-summary*"
            element={
              <AdminRoute>
                <TemplateSummary />
              </AdminRoute>
            }
          />
          <Route
            path="/templatemanagement"
            element={
              <AdminRoute>
                <TemplateManagement />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/systemnotifications"
            element={
              <AdminRoute>
                <SystemNotifications />
              </AdminRoute>
            }
          />
          <Route path="/reports" element={<DynamicReports />} />
          <Route path="/report/:reportName" element={<DynamicReportDetail />} />
          <Route
            path="/admin/roles"
            element={
              <AdminRoute>
                <AdminRoles />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/role/*"
            element={
              <AdminRoute>
                <EditRole />
              </AdminRoute>
            }
          />
          <Route path="/catalog/*" element={<Catalog />} />
          <Route path="edit/*" element={<Edit />} />
          <Route path="quote/*" element={<Quote />} />
          <Route path="view/*" element={<View />} />
          <Route path="/change-orders/create/confirm" element={<Confirm />} />
          <Route path="/change-orders/view/*" element={<ChangeOrders />} />
          <Route path="/change-orders/viewall/*" element={<ChangeOrders />} />
          <Route
            path="/change-orders/cancellation/confirm"
            element={<ChangeOrders />}
          />
        </Routes>
      </Layout>
    </>
  );
}
