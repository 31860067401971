import { useState, useContext, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  FormControl
} from "@mui/material";
import {
  DataGridPro,
  useGridApiRef,
  GridRenderCellParams,
  GridRowSelectionModel
} from "@mui/x-data-grid-pro";
import { MeasuredWizard } from "../../assets/icons/hhIcons";
import ReusableDialog from "../../components/Dialog";
import {
  UIState,
  UIContext,
  OptionTypes,
  HighlandCategoryOption
} from "../../providers/UIProvider";
import { dispatchError, removeFirstRowIfEmpty } from "../../common/fx";
import { getMeasuredOptions } from "../../apiCalls";
import { MeasuredOptionsType } from "../../providers/UIProvider";
import { number } from "yup";

interface MeasuredOptionsWizardProps {
  disabled?: boolean;
  mode: "quote" | "edit" | "view";
};

const MeasuredOptionsWizard = (props:MeasuredOptionsWizardProps) => {
  const [open, setOpen] = useState(false);
  const [measuredOptionsdata, setMeasuredOptionsData] = useState<MeasuredOptionsType[]>([]);
  const [materials, setMaterials] = useState<string[]>([]);
  const [material, setMaterial] = useState<string | null>(null);
  const [levels, setLevels] = useState<string[]>([]);
  const [level, setLevel] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<MeasuredOptionsType[]>([]);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<any[]>([]);
  const [rowSelectionModel, setGridRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [notes, setNotes] = useState<string>("");
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const apiRefGrid = useGridApiRef();

  useEffect(() => {
    let measuredOptionsPayload : any = {};
    if(props.mode === "edit") {
      measuredOptionsPayload.planId = state.changeOrder.plan.planId;
      measuredOptionsPayload.projectId = state.changeOrder.plan.projectID;
    }
    if(props.mode === "quote") {
      measuredOptionsPayload.planId = state.quote.plan.planId;
      measuredOptionsPayload.projectId = state.quote.plan.projectID;
    }
    getMeasuredOptions(
      measuredOptionsPayload,
    (res:any) => {
      setMeasuredOptionsData(res.data);
      const allMaterials = res.data.map((item: any) => item.materialType);
      setMaterials(allMaterials.filter((item:any, ix:number, oa:any) => oa.indexOf(item) === ix));
    },
    (err:any) => {
      dispatch(
        dispatchError({
          message: err.message,
          statusText: err.response.statusText,
          title: err.response.data.title,
          status: err.response.status,
          detail: err.response.data.detail,
          data: err.response.data,
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLevel(null);
    if(material === null) {
      setLevels([]);
      setFilteredData([]);
      return;
    }
    const allLevels = measuredOptionsdata
      .filter((item: any) => item.materialType === material)
      .map((item: any) => item.optionLevel);
    setLevels(allLevels
      .filter((item:any, ix:number, oa:any) => oa.indexOf(item) === ix)
      .sort((a: any, b: any) => {
        if(a < b) return -1;
        if(a > b) return 1;
        return 0;
      }));
    setFilteredData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material]);

  useEffect(() => {
    if(level === null) {
      setFilteredData([]);
      return;
    }
    setFilteredData(measuredOptionsdata
      .filter((item:MeasuredOptionsType) => item.materialType === material && item.optionLevel === level)
      .sort((a: any, b: any) => {
        if(a.optionLocation < b.optionLocation) return -1;
        if(a.optionLocation > b.optionLocation) return 1;
        return 0;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    setGridRows(filteredData);
  }, [filteredData]);

  useEffect(() => {
    setGridColumns(buildColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRows]);

  const buildColumns = () => {
    return [
      {
        flex: 3,
        field: "optionLocation",
        headerName: "Location",
        editable: false,
        type: "string"
      },
      {
        flex: 3,
        field: "salesPrice",
        headerName: "Price",
        editable: false,
        renderCell: (params: GridRenderCellParams) => `$ ${parseFloat(params.value).toFixed(2)}`,
        type: "number"
      }
    ];
  };

  const getRowId = (row:any) => {
    return row.salesPriceControlID;
  };

  const handleRowSelection = (newSelectionModel:GridRowSelectionModel) => {
    setGridRowSelectionModel(newSelectionModel);
  };

  const saveCurrentOptions = () => {
    let maxSortOrder = 0;
    let baseRowIx = 0;
    const moHC = state.highlandCategories.find((hc:HighlandCategoryOption) => hc.key === "MeasuredOption");
    const newPendingItems = removeFirstRowIfEmpty([...state.pendingItems], moHC);

    newPendingItems.forEach((row: OptionTypes, rowIx: number) => {
      if (row.highlandCategory.key === "MeasuredOption" &&
        row.sortOrder > maxSortOrder) {
        maxSortOrder = row.sortOrder;
        baseRowIx = rowIx;
      }
    });

    rowSelectionModel.forEach((salesPriceControlID:any) => {
      //const spci = parseInt(salesPriceControlID);
      const option = state.availableOptions.find((option:OptionTypes) =>
        option.salesPriceControlID === salesPriceControlID
      );
      if(option) {
        maxSortOrder++;
        const newPendingItem = {
          ...option,
          highlandCategory: moHC,
          id: Math.random().toString(16).slice(2),
          isNew: true,
          notes: notes,
          option: option.category,
          quantity: 1,
          unitPrice: option.salesPrice,
          totalPrice: option.salesPrice,
          sortOrder: maxSortOrder
        };
        newPendingItems.splice(baseRowIx + 1, 0, newPendingItem);
        baseRowIx++;
      }
      dispatch({
        type: props.mode === "edit" ? "ChangeOrderPending" : "QuotePending",
        payload: newPendingItems,
        source: "MeasuredOptions Wizard",
      });
    });
  }

  const handleClear = () => {
    setMaterial(null);
    setLevel(null);
    setFilteredData([]);
    setNotes("");
  };
  
  return (
    <ReusableDialog
      buttonStyle={{ fontSize: "12px", marginLeft: "4px", padding: "4px 6px" }}
      buttonVariant="outlined"
      buttonFullWidth={false}
      disabled={props.disabled || false}
      setIsOpen={(value) => setOpen(value)}
      icon={<MeasuredWizard />}
      isOpen={open}
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Stack
                direction="column"
                marginY={(theme) => theme.spacing(2)}
                spacing={2}
              >
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    fullWidth
                    //loading
                    multiple={false}
                    onChange={(e, value:any) => setMaterial(value)}
                    options={materials}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        label="Material"
                        fullWidth
                        variant="outlined"
                      />
                    }
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    fullWidth
                    disabled={material === null}
                    multiple={false}
                    onChange={(e, value:any) => setLevel(value)}
                    options={levels}
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        label="Level"
                        fullWidth
                        variant="outlined"
                      />
                    }
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  {filteredData.length > 0 && gridColumns && gridRows &&
                    <DataGridPro
                      apiRef={apiRefGrid}
                      checkboxSelection
                      columnHeaderHeight={46}
                      columns={gridColumns}
                      disableMultipleRowSelection={true}
                      getRowId={getRowId}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      onRowSelectionModelChange={handleRowSelection}
                      pageSizeOptions={[5, 10, 20]}
                      pagination
                      rowCount={gridRows.length}
                      rowHeight={46}
                      rows={gridRows}
                      rowSelectionModel={rowSelectionModel}
                    />
                  }
                </Box>
                { filteredData.length > 0 && gridColumns && gridRows &&
                  <Box marginY={(theme) => theme.spacing(2)}>
                    <FormControl fullWidth>
                      <TextField
                        label="Notes for these options"
                        maxRows={4}
                        multiline={true}
                        onChange={(event) => setNotes(event.target.value)}
                        placeholder="Add a note for all the Measured options to be added"
                        rows={2}
                        value={notes}
                      />
                    </FormControl>
                  </Box>
                }
              </Stack>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                handleClear();
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={!rowSelectionModel || rowSelectionModel.length === 0}
              onClick={() => {
                saveCurrentOptions();
                setOpen(false);
                handleClear();
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </DialogActions>
        </>
      }
      title="Measured Options"
      toolTipTitle="Measured Options Wizard"
    />
  );
}

export default MeasuredOptionsWizard;